// 表示用の日付を作成する
const createDateForDisplay = (pYear, pMonth, pDate) => {
  let _return = "";

  let _day = new Date(pYear, pMonth, pDate);
  let _youbi = _day.getDay();
  let _youbiList = ["日", "月", "火", "水", "木", "金", "土"];
  let _youbi_disp = _youbiList[_youbi];
  _return =
    pYear + "年" + (pMonth + 1) + "月" + pDate + "日" + "(" + _youbi_disp + ")";
  return _return;
};
// localStorageから削除する
const removeLocalStorageItem = () => {
  localStorage.removeItem("menkai-info");
};

// localStorageに保存する
const setLocalStorageItem = (_obj) => {
  let _setObj = {
    facility_code: _obj.facility_code,
    facility_name: _obj.facility_name,
    url: _obj.url,
    consent: _obj.consent,
    // year: _obj.year,
    // month: _obj.month,
    // date: _obj.date,
    // timezone: _obj.timezone,
    // timezone_disp: _obj.timezone_disp,
    // floor: _obj.floor,
    // room: _obj.room,
    // patient_name: _obj.patient_name,
    // visitor: [],
  };
  localStorage.setItem("menkai-info", JSON.stringify(_setObj));
};

// localStorageから取得する
const getLocalStorageItem = () => {
  let _getObj = {};
  _getObj = JSON.parse(localStorage.getItem("menkai-info"));
  return _getObj;
};

// localStorageから特定の情報を取得する
const getLocalStorageItem_specific = (pKey) => {
  let _getObj = {};
  _getObj = JSON.parse(localStorage.getItem("menkai-info"));
  return _getObj[pKey];
};

// 全角を半角にする
const funcZenToHan = (pVal) => {
  let _result = "";
  if (pVal.length == 0) {
    return pVal;
  }

  // 全角を半角に置換する
  const _zen =
    "０１２３４５６７８９ＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺａｂｃｄｅｆｇｈｉｊｋｌｍｎｏｐｑｒｓｔｕｖｗｘｙｚ－＋．＿＠。";
  const _han =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-+._@.";

  // 入力文字列の各文字をチェック
  let _str = pVal;
  for (let i = 0; i < _str.length; i++) {
    let _char = _str[i];
    let _index = _zen.indexOf(_char);

    if (_index !== -1) {
      // 全角を半角に変換
      _result += _han[_index];
    } else {
      // 変換の必要がない場合はそのまま
      _result += _char;
    }
  }
  return _result;
};

// 連絡先検証
const funcValidateContact = (pModel) => {
  let _break = false;
  // 連絡先の入力がない場合は処理中断
  if (pModel.contact.length == 0) {
    return;
  }

  // 置換した結果を正規表現で判定する
  // const _regex = /^(?!.*--)(?!^-)(?!.*-$)[0-9-]+$/;
  const _regex = /^(?!.*--)(?!^-)(?!.*-$)[0-9]+(-[0-9]+){0,2}$/;

  // 正規表現から外れる場合
  if (!_regex.test(pModel.contact)) {
    _break = true;
  }
  // 6桁未満の場合
  if (pModel.contact.length < 6) {
    _break = true;
  }
  return _break;
};

// メールアドレス検証
const funcValidateMailaddress = (pModel) => {
  let _break = false;
  // メールアドレスの入力がない場合は処理中断
  if (pModel.mailaddress.length == 0) {
    return;
  }

  const _regex =
    /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

  // 正規表現から外れる場合
  if (!_regex.test(pModel.mailaddress)) {
    _break = true;
  }
  return _break;
};

const funcRemoveSpaces = (pVal) => {
  if (pVal == undefined || pVal == "") {
    return pVal;
  }
  return pVal.replace(/[\s\u3000]+/g, "");
};

module.exports = {
  createDateForDisplay,
  removeLocalStorageItem,
  setLocalStorageItem,
  getLocalStorageItem,
  getLocalStorageItem_specific,
  funcZenToHan,
  funcValidateContact,
  funcValidateMailaddress,
  funcRemoveSpaces,
};
